<template>
  <div class="up-courses">
    <h1 class="up-main-title">Все курсы</h1>
    <AppCollapse v-for="direction in coursesTree" :key="direction.id">
      <template slot="title">{{ direction.name }}</template>
      <AppCollapse
        v-for="category in direction.childs"
        :key="`category-${category.id}`"
      >
        <template slot="title">{{ category.name }}</template>
        <router-link
          v-for="course in category.courses"
          :key="`course-${direction.id}-${course.id}`"
          class="el-button el-button--text up-courses__subcategory"
          :to="`/methodist/courses/${course.id}`"
        >
          {{ course.name }} <i class="el-icon-edit" />
        </router-link>
      </AppCollapse>
    </AppCollapse>
    <p v-if="coursesTree.length === 0 && !loading" class="up-empty-list-text">
      Список пуст
    </p>
  </div>
</template>

<script>
import AppCollapse from "@/components/AppCollapse";

import showErrorMessage from "@/mixins/showErrorMessage.mixin";

import { mapActions } from "vuex";

export default {
  name: "AllCourses",
  mixins: [showErrorMessage],
  components: { AppCollapse },
  data: () => ({
    coursesTree: [],
    loading: true,
  }),
  methods: {
    ...mapActions(["setNavigationText"]),
    async fetchTree() {
      this.$nprogress.start();

      try {
        let res = await this.$axios.get("/constructor/treeFull");

        this.coursesTree = res.data;
      } catch (e) {
        this.showErrorMessage(e);
      } finally {
        this.loading = false;
        this.$nprogress.done();
      }
    },
  },
  created() {
    this.setNavigationText("Просмотр списка всех курсов");

    this.fetchTree();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/common/courses.scss";
</style>